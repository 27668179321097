
























import { ReqFilterTable } from '@/interface';
import { Vue, Component, Ref, Prop, Emit } from 'vue-property-decorator';

@Component
export default class PaginationBox extends Vue {
  @Prop({default: {showCount: 10, currentPage: 1, totalResult: 0}})
  reqFilterTable!: ReqFilterTable<any>;
  @Emit()
  sizeChange(size: number){ return size; }
  @Emit()
  currentChange(page: number){ return page; }
}
